import { Edge } from '@/components/Edge';
import { StateProviders } from '@/components/StateProviders';
import { initAllerUserConsentQueue } from '@/utils/initAllerUserConsentQueue';
import { withComponentsRenderDebug } from '@/utils/withComponentsRenderDebug';
import { withCookiesInterceptor } from '@/utils/withCookiesInterceptor';
import { withDevelopmentConsentCookie } from '@/utils/withDevelopmentConsentCookie';
import { withFetchInterceptor } from '@/utils/withFetchInterceptor';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import type { AppProps } from 'next/app';
import '../base/css/styles.css';

withComponentsRenderDebug();
withCookiesInterceptor();
withDevelopmentConsentCookie();
withFetchInterceptor();

function MyApp({ Component, pageProps }: AppProps) {
  initAllerUserConsentQueue();

  return (
    <ErrorBoundary>
      <StateProviders {...pageProps}>
        <Edge>
          <Component {...pageProps} />
        </Edge>
      </StateProviders>
    </ErrorBoundary>
  );
}

export default MyApp;
