import { Component } from '@/types/component';
import { cn } from '@/utils/cn';
import { StandaloneEdge, StandaloneEdgeProps } from 'base/components/Edge';
import localFonts from 'next/font/local';

const fontPrimary = localFonts({
  display: 'swap',
  preload: false,
  src: [
    { path: '../../../public/fonts/Poppins-ExtraLight.woff2', weight: '275', style: 'normal' },
    { path: '../../../public/fonts/Poppins-Regular.woff2', weight: '400', style: 'normal' },
    { path: '../../../public/fonts/Poppins-Medium.woff2', weight: '500', style: 'normal' },
  ],
  variable: '--font-primary',
});

const fontSecondary = localFonts({
  display: 'swap',
  preload: false,
  src: [
    { path: '../../../public/fonts/PTSerif-Regular.woff2', weight: '400', style: 'normal' },
    { path: '../../../public/fonts/PTSerif-Bold.woff2', weight: '700', style: 'normal' },
    { path: '../../../public/fonts/PTSerif-Italic.woff2', weight: '400', style: 'italic' },
    { path: '../../../public/fonts/PTSerif-BoldItalic.woff2', weight: '700', style: 'italic' },
  ],
  variable: '--font-secondary',
});

const fontTertiary = localFonts({
  display: 'swap',
  preload: true,
  src: [{ path: '../../../public/fonts/Caveat-Bold.woff2', weight: '700', style: 'normal' }],
  variable: '--font-tertiary',
});

const fonts = cn(fontPrimary.variable, fontSecondary.variable, fontTertiary.variable);

export const AllasStandaloneEdge: Component<StandaloneEdgeProps> = ({ children }) => {
  return (
    <StandaloneEdge favicon="/favicon.svg" className={fonts}>
      {children}
    </StandaloneEdge>
  );
};
